export const CHAT_URL =
  process.env.NEXT_PUBLIC_MODE === 'production'
    ? `https://shopping-gpt-oksoon-eks.buzzni.com`
    : `https://shopping-gpt-dev-eks.buzzni.com`;
export const CHAT_AIAAS_URL = 'https://shopping-gpt-aiaas-eks.buzzni.com';
export const USER_URL = `https://chat-user.buzzni.com`;

export const ANALYTICS_URL =
  process.env.NEXT_PUBLIC_MODE === 'production'
    ? `https://shopping-gpt-stat.buzzni.com`
    : `https://dev-shopping-gpt-stat.buzzni.com`;

export const APLUS_URL =
  process.env.NEXT_PUBLIC_MODE === 'production'
    ? 'https://public-api.aplusai.ai'
    : 'https://dev-public-api.aplusai.ai';
